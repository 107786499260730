@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';

body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

[class^=ais-] {
  font-size: inherit !important;
  box-sizing: border-box;
}
